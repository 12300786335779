import React, { useEffect, useState } from 'react';
//import {Link, useHistory} from 'react-router-dom'; //usado para linkar outras paginas.
import api from '../../services/api.js'
import Loading from '../Loading/index.js'
import { Context } from '../../Context/AuthContext.js';
import { Dropdown, Tab, Checkbox } from 'semantic-ui-react'
import { FiEdit, FiTrash2, FiInfo } from 'react-icons/fi';
import { BiDetail } from 'react-icons/bi';
import ReactTooltip from 'react-tooltip';
import { Modal, Button } from 'react-bootstrap'


import './styles.css';
import '../../global.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Menu } from '../../global.js'



export default function GestaoWorkspaces() {

  const [carregando, setCarregando] = useState(false);

  const panes = [
    {
      menuItem: 'Minhas áreas de trabalho',
      render: () => <Tab.Pane attached={false}><GerenciarWorkspaces /></Tab.Pane>,
    },
    {
      menuItem: 'Gerenciar usuários',
      render: () => <Tab.Pane attached={false}><GerenciarConvidado /></Tab.Pane>,
    },
    {
      menuItem: 'Visualizar registros de atividades',
      render: () => <Tab.Pane attached={false}>Em breve</Tab.Pane>,
    }
  ]

  return (
    <div>
      <Menu />

      <div className="reavaliacao-retangulo1">
        <div className="reavaliacao-retangulo2">
          <div className="reavaliacao-intro">
            <h2>Áreas de trabalho</h2>
          </div>

          <div className="reavaliacao-login-box">

            <Tab menu={{ pointing: true }} panes={panes} />

          </div>

        </div>
        <Loading loading={carregando} message='Carregando...' />
      </div>

    </div>


  );
}

function GerenciarWorkspaces() {

  const { aviso, atualizarWorkspaces } = React.useContext(Context);

  const [carregando, setCarregando] = useState(false);

  const [workspaces, setWorkspaces] = useState([]);
  const [workspace, setWorkspace] = useState('');
  const [modalShow, setModalShow] = useState(false)
  const [modalShow2, setModalShow2] = useState(false)

  async function handleDeleteWorkspace(id) {
    try {
      setCarregando(true)
      await api.delete(`workspace/${id}`)
      setWorkspaces(workspaces.filter(item => item.id !== id))
      aviso('success', 'Área de trabalho deletada com sucesso')

      if (id === parseInt(sessionStorage.getItem('workspaceId'))) {
        sessionStorage.setItem('workspaceId', workspaces.filter(item => item.id !== id)[0].id)
      }

      atualizarWorkspaces()
    } catch (error) {
      if (error.response.data.mensagem.startsWith("Não")) {
        aviso("warning", error.response.data.mensagem)
      } else {
        aviso('error', error)
      }
    } finally {
      setCarregando(false)
    }
  }

  async function inicial() {

    setCarregando(true)
    try {

      await Promise.all([
        api.get('workspace')
      ]).then(response => {
        setWorkspaces(response[0].data.resultado)
      })

    } catch (error) {
      console.log(error)
      aviso('error', error)
    } finally {
      setCarregando(false)
    }
  }

  useEffect(() => {
    inicial()
  }, [])

  return (
    <div>
      <Loading loading={carregando} message='Carregando...' />
      <ModalCadastro show={modalShow} workspace={workspace} workspaces={workspaces} onHide={() => { setModalShow(false); inicial(); setWorkspace('') }} />
      <ModalCadastroConvidado show={modalShow2} workspace={workspace} onHide={() => { setModalShow2(false); inicial(); setWorkspace('') }} />
      <div style={{ justifyContent: 'center', marginBottom: '10px', display: 'flex' }}>
        <label style={{ fontSize: '20px' }}>Minhas áreas de trabalho</label>
      </div>
      <div className="reavaliacao-menu-pergunta">
        <div className="reavaliacao-menu-pergunta2">
          <label>&nbsp;</label>
          <button onClick={() => { setWorkspace(''); setModalShow(true) }}>Cadastrar novo</button>
        </div>
      </div>
      <div>
        <div style={{ marginTop: '30px' }}>
          <h3>Cadastros:</h3>
        </div>
        {workspaces.length < 1 ?
          <div>
            <label>Nenhum registro</label>
          </div>
          :
          <div className="scroll-box">
            {workspaces.map(item => (
              <div key={item.id} className="reavaliacao-list-box" style={{ cursor: 'default' }}>
                <ReactTooltip id={toString(item.id)} place="top" effect="solid"></ReactTooltip>
                <div className="reavaliacao-name">
                  <h3 style={{ cursor: 'pointer' }} onClick={() => {
                    setWorkspace(item.id)
                    setModalShow2(true)
                  }}>{item.name}</h3>
                </div>
                <div className="reavaliacao-icons">
                  <i className="reavaliacao-edit"> <FiEdit data-tip="Editar" data-for={toString(item.id)} onClick={() => { setWorkspace(item.id); setModalShow(true) }} />  </i>
                  <i className="reavaliacao-trash">
                    <FiTrash2 data-tip="Excluir" data-for={toString(item.id)}
                      onClick={async () => {
                        const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE O ITEM SELCIONADO E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + item.name + ' :')
                        if (r === "excluir") {
                          await handleDeleteWorkspace(item.id);
                          inicial();
                        } else {
                          aviso("warning", "Nome incorreto, exclusão cancelada", true)
                        }
                      }} /> </i>
                </div>

              </div>
            )
            )}
          </div>
        }
      </div>
    </div>
  );

}

function GerenciarConvidado() {

  const { aviso } = React.useContext(Context);

  const [carregando, setCarregando] = useState(false);
  const [workspace, setWorkspace] = useState('');
  const [workspaces, setWorkspaces] = useState([]);

  async function inicial() {
    setCarregando(true)
    try {
      await Promise.all([
        api.get('workspace')
      ]).then(response => {
        setWorkspaces(response[0].data.resultado)
      })

    } catch (error) {
      console.log(error)
      aviso('error', error)
    } finally {
      setCarregando(false)
    }
  }

  useEffect(() => {
    inicial()
  }, [])

  return (
    <div>
      <Loading loading={carregando} message='Carregando...' />
      <div style={{display: 'inline-grid', marginBottom:'10px'}}>
        <label>Área de trabalho: </label>
        <select value={workspace} onChange={(e) => setWorkspace(e.target.value)}>
          <option key={-1} value="">Selecione uma opção</option>
          {workspaces.map(workspace => (
            <option key={workspace.id} value={workspace.id}>{workspace.name}</option>
          ))}
        </select>
      </div>
      {workspace !== '' && (
        <div>
          <CadastroConvidado workspace={workspace} />
        </div>
      )}

    </div>
  );

}

function ModalCadastro(props) {

  const [carregando, setCarregando] = useState(false);
  const [name, setName] = useState('')

  const { aviso, atualizarWorkspaces } = React.useContext(Context);

  async function inicial() {
    setName('')
    if (props.show && props.workspace !== '') {
      let nome = props.workspaces.filter(item => item.id === props.workspace)[0].name
      setName(nome)
    }

  }
  useEffect(() => {
    inicial()
  }, [props.show])

  const handleRegister = async () => {

    var data = {
      name,
      status: 'enable'
    }

    if (name === '') {
      aviso('warning', "Preencha todos os campos!")
    } else {
      try {
        setCarregando(true)
        if (props.workspace !== '') {
          await api.patch("workspace/" + props.workspace, data)
        } else {
          await api.post("workspace/", data)
        }
        atualizarWorkspaces()
      } catch (error) {
        aviso('error', error)
      } finally {
        setCarregando(false)
        props.onHide()
      }
    }

  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.workspace !== '' ? "Editar" : "Cadastrar"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Loading loading={carregando} message='Carregando...' />

        <div className="trabexp">

          <div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
            <label>Nome</label>
            <input value={name} onChange={e => setName(e.target.value)} />
          </div>

        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleRegister}>{props.workspace !== '' ? "Salvar" : "Cadastrar"}</Button>
        <Button onClick={props.onHide}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  );
}

function ModalCadastroConvidado(props) {

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Convidados
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <CadastroConvidado workspace={props.workspace} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  );
}

function CadastroConvidado(props) {

  const [carregando, setCarregando] = useState(false);
  const [email, setEmail] = useState('')
  const [guests, setGuests] = useState([])

  const { aviso } = React.useContext(Context);


  async function inicial() {
    const response = await api.get('workspace_user/' + props.workspace)
    setGuests(response.data.resultado)
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const isValidEmail = (email) => {
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (props.workspace !== '') {
      inicial()
    }
  }, [props.workspace])

  const handleRegister = async () => {

    if (isValidEmail(email)) {
      var data = {
        guest: email,
        workspace_id: props.workspace
      }

      try {
        setCarregando(true)
        await api.post("workspace_user/", data)
        inicial()
        aviso('success', "Usuário convidado com sucesso")
        setEmail('')
      } catch (error) {
        if (error.response.data.mensagem.startsWith("Usu") || error.response.data.mensagem.startsWith("Ope")) {
          aviso("warning", error.response.data.mensagem)
        } else if (error.response.data.mensagem.startsWith("Dup")) {
          aviso("warning", 'Convidado já cadastrado')
        } else {
          aviso('error', error)
        }
      } finally {
        setCarregando(false)
      }

    } else {
      aviso('warning', 'Email inválido')
    }

  }

  const handleDeleteGuest = async (id) => {
    try {
      setCarregando(true);
      await api.delete(`workspace_user/${id}`);
      setGuests(guests.filter(item => item.id !== id));
      aviso('success', 'Convidado excluído com sucesso');
    } catch (error) {
      console.log(error);
      aviso('error', error);

    } finally {
      setCarregando(false);
    }
  }

  return (
    <div>
      <Loading loading={carregando} message='Carregando...' />
      <div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
        <label>Digite o email do usuário que deseja conceder o acesso a esta área de trabalho:</label>
        <input type='email' value={email} onChange={e => setEmail(e.target.value)} />
        {email !== '' && (
          <button className='button-guest' onClick={handleRegister}>Adicionar usuário</button>
        )}
      </div>

      <div>
        <b>Usuários externos com acesso:</b>
        <div className="guest-box">
          {guests.length !== 0 ? guests.map(guest => (
            <div key={guest.id} className="guest-list" style={{ marginTop: '5px' }}>
              <ReactTooltip id={toString(guest.id)} place="top" effect="solid" />
              <div style={{ display: 'inline-grid' }}>
                <span><b>Usuário: </b>{guest.display_name}</span>
                <span><b>Email: </b>{guest.user_email}</span>
              </div>
              <div className="reavaliacao-icons">
                <i>
                  <FiTrash2 data-tip={"Excluir"} data-for={toString(guest.id) + guest.nome}
                    className="reavaliacao-trash"
                    onClick={() => {
                      const r = window.confirm('Tem certeza que deseja excluir esse convidado?')
                      if (r === true) {
                        handleDeleteGuest(guest.id);
                      }
                    }} />
                </i>
              </div>

            </div>
          )) : <span>Nenhum registro</span>}
        </div>
      </div>
    </div>

  );
}
