import React from 'react';
import { FiEdit, FiTrash2, FiChevronLeft } from 'react-icons/fi';
import { BiLink, BiUnlink } from 'react-icons/bi';

import { Context } from '../../../Context/AuthContext';
import { Link } from 'react-router-dom'; //usado para linkar outras paginas.
import api from '../../../services/api';
import Loading from '../../Loading';
import { useHistory } from 'react-router-dom'
import { Dropdown, Checkbox } from 'semantic-ui-react'
import { Modal, Button } from 'react-bootstrap'
import { useState, useEffect } from 'react';

import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactTooltip from 'react-tooltip';
import { Menu } from '../../../global.js';



export default function Cadastraratividades() {

	const [identificacao, setIdentificacao] = useState("");
	const [descricao, setDescricao] = useState("");

	const { aviso, FullLicensed } = React.useContext(Context);

	const estabelecimentoId = sessionStorage.getItem('estabelecimentoId')
	const estabelecimentoName = sessionStorage.getItem('estabelecimentoName')
	const estabelecimentoModalidade = sessionStorage.getItem('estabelecimentoModalidade')

	const [revisao, setRevisao] = useState(false)
	const [idTarefa, setIdTarefa] = useState(null)
	const [carregando, setCarregando] = useState(false);
	const [fontes, setFontes] = useState([]);
	const [elaboradores, setElaboradores] = useState([]);
	const [currentValues, setCurrentValues] = useState([]);
	const [searchQuery, setSearchQuery] = useState('')
	const [currentValues2, setCurrentValues2] = useState([]);
	const [ocupacoes, setOc] = useState([])
	const [opcoes_fontes, setOpFontes] = useState([])
	const [ordem, setOrdem] = useState(null)

	const [modalShow, setModalShow] = useState(false)
	const [modalShow2, setModalShow2] = useState(false)
	const [modalFontes, setModalFontes] = useState(false)

	const [verifica, setVerifica] = useState(false)

	const history = useHistory();

	useEffect(() => {
		setCarregando(true)

		async function initial() {

			try {
				var fonte
				var aux

				const data = {
					nome: identificacao,
					descricao: descricao
				};

				if (sessionStorage.getItem('atividadeNovo') == "true") {
					sessionStorage.removeItem('atividadeId')
				}

				if (sessionStorage.getItem('atividadeId') !== null) {
					setIdTarefa(sessionStorage.getItem('atividadeId'))
					setRevisao(true)
					setVerifica(true)

					await Promise.all([
						api.get('tarefa_elaborador?tarefa_id=' + sessionStorage.getItem('atividadeId')),
						api.get('fonte_circunstancia?tarefa_id=' + sessionStorage.getItem('atividadeId'))
					]).then(response => {

						let copy2 = Array.from(currentValues2);
						response[0].data.resultado.forEach(elaborador => {
							copy2.push(elaborador.elaborador_id);
						})
						setCurrentValues2(copy2)

						let copy3 = Array.from(currentValues);
						response[1].data.resultado.forEach(fonte => {
							copy3.push(fonte.nome);
						})
						setCurrentValues(copy3);
						fonte = copy3

					})

				} else {
					try {
						const response = await api.post('tarefa?estabelecimento_id=' + estabelecimentoId, data)
						sessionStorage.setItem('atividadeId', response.data.id_tarefa);
						setIdTarefa(response.data.id_tarefa);

						if (sessionStorage.getItem("FontesCircunstancias") !== null) {
							aux = true
							fonte = JSON.parse(sessionStorage.getItem("FontesCircunstancias"))
							setCurrentValues(fonte)
						}

					} catch (error) {
						aviso('error', error)
					}
				}

				await Promise.all([
					api.get((`ocupacao?tarefa_id=${sessionStorage.getItem('atividadeId')}`)),
					api.get('elaborador?empresa_id=' + sessionStorage.getItem('empresaId')),
					api.get('tarefa?estabelecimento_id=' + estabelecimentoId),
					api.get('sugestoes/4')
				]).then(response => {
					setOc(response[0].data.resultado)
					setOpFontes(response[3].data.resultado)
					let copy = Array.from(elaboradores);
					response[1].data.resultado.forEach(elaborador => {
						copy.push({ key: elaborador.id, text: elaborador.nome, value: elaborador.id });
					})
					setElaboradores(copy)
					response[2].data.resultado.filter(valor => valor.id === parseInt(sessionStorage.getItem('atividadeId'))).map(valor => { setDescricao(valor.descricao); setIdentificacao(valor.nome); setOrdem(valor.ordem) })
					if (sessionStorage.getItem('atividadeNovo') != "true" || aux == true) {
						fonte.forEach(item => {
							var verifica = false
							response[3].data.resultado.forEach(item2 => {
								if (item === item2.value) {
									verifica = true
								}
							})
							if (!verifica) {
								response[3].data.resultado.push({ key: item, text: item, value: item })
							}
						})
					}
					setFontes(response[3].data.resultado)
				})

			} catch (error) {

				aviso('error', error)
			} finally {
				setCarregando(false)
			}


		} initial()

	}, [])

	const handleChange = (e, { value }) => {
		setCurrentValues(value);
		setSearchQuery('')
	}

	const handleSearchChange = (e, { searchQuery }) => {
		setSearchQuery(searchQuery)
	}

	const handleLabelClick = (e, { value }) => {
		setCurrentValues(currentValues.filter((item) => item != value))
		setSearchQuery(value)
	}

	const handleAddition = (e, { value }) => {
		const copy = Array.from(fontes);
		copy.push({ key: value, text: value, value: value })
		setFontes(copy);
	}

	const handleChange2 = (e2, { value }) => {
		setCurrentValues2(value);
	}

	const DropFonte = React.useMemo(() => (
		<Dropdown
			options={fontes}
			placeholder={"Selecione múltiplos"}
			search
			selection
			lazyLoad={true}
			fluid
			multiple
			allowAdditions
			clearable
			additionLabel="Adicionar "
			value={currentValues}
			searchQuery={searchQuery}
			onAddItem={handleAddition}
			onChange={handleChange}
			onSearchChange={handleSearchChange}
			onLabelClick={handleLabelClick}
		/>
	), [fontes, currentValues, searchQuery]);

	const DropElaboradores = React.useMemo(() => (
		<Dropdown
			options={elaboradores}
			placeholder={"Selecione múltiplos"}
			lazyLoad={true}
			search
			selection
			clearable
			fluid
			multiple
			value={currentValues2}
			onChange={handleChange2}
		/>
	), [elaboradores, currentValues2]);

	async function handleRegister(e) {
		e.preventDefault();
		const data = {
			nome: identificacao,
			descricao: descricao,
			ordem: ordem
		};

		if (data.nome === "" || currentValues2.length === 0 || currentValues.length === 0 || ocupacoes.length < 1) {
			if (ocupacoes.length < 1) {
				aviso('warning', 'Cargos devem ser preenchidos')
			} else {
				aviso('warning', "Preencha todos os campos!")
			}

		} else {
			try {
				setCarregando(true);
				await api.delete('tarefa_elaborador/' + idTarefa)
				await api.delete('fonte_circunstancia/' + idTarefa)

				currentValues.forEach(fonte => {
					var v_fonte = false
					opcoes_fontes.forEach(item => {
						if (fonte == item.value) {
							v_fonte = true
						}
					})

					if (!v_fonte) {

						var valor = {
							value: fonte,
							text: fonte
						}

						api.post("sugestoes/4", valor)
					}
				})

				currentValues2.forEach(elaborador => {
					api.post('tarefa_elaborador?tarefa_id=' + idTarefa + '&elaborador_id=' + elaborador)
				});

				currentValues.forEach(fonte => {
					api.post('fonte_circunstancia?tarefa_id=' + idTarefa, { nome: fonte })
				})

				await api.patch(revisao && sessionStorage.getItem('revisao') != 'true' ? 'tarefa/' + idTarefa + '?revisao=true' : 'tarefa/' + idTarefa + '?revisao=false', data)
				sessionStorage.setItem("FontesCircunstancias", JSON.stringify(currentValues))
				aviso('success', estabelecimentoModalidade === 'Tarefa' ? "A tarefa foi salva com sucesso" : "A atividade foi salva com sucesso")

			} catch (error) {

				aviso('error', error);

			} finally {
				sessionStorage.setItem('revisao', revisao)
				setCarregando(false)
				history.push("GerenciarAtividades")
			}
		}
	}

	async function handleUnlinkOc(id, nome, wait) {
		try {
			if(wait){
				setCarregando(true)
				await api.delete(`trabalhadores_expostos/${id}?tipo=clear&nome=${nome}&tarefa=${sessionStorage.getItem('atividadeId')}`)
				aviso('success', 'Cargo desvinculado com sucesso');
			}else{
				api.delete(`trabalhadores_expostos/${id}?tipo=clear&nome=${nome}&tarefa=${sessionStorage.getItem('atividadeId')}`)
			}	
			
		} catch (error) {
			aviso('error', error);
		} finally {
			setCarregando(false);
		}
	}

	async function handleDeleteOc(id) {
		try {
			await api.delete(`ocupacao/${id}`)
			setOc(ocupacoes.filter(ocupacao => ocupacao.id !== id))
			aviso('success', 'Cargo removido com sucesso')
		} catch (error) {
			aviso('error', error)
		}
	}

	async function UpdateOcupacao() {
		try {
			await api.get((`ocupacao?tarefa_id=${sessionStorage.getItem('atividadeId')}`)).then(response => {
				setOc(response.data.resultado)
			})
		} catch (error) {
			aviso('error', error)
		}

	}

	return (
		<div>
			<Menu />
			<ReactTooltip backgroundColor="#006ab4" className="tip" id="aux" effect="solid" multiline={true}></ReactTooltip>
			<RemoveFontes show={modalFontes} backdrop="static" onHide={() => { setModalFontes(false) }} />
			<div style={{ textAlign: "left", marginLeft: "10px", marginTop: "5px" }}><h4>Empresa: {sessionStorage.getItem('empresaName')}<br />Organização: {estabelecimentoName}</h4></div>
			<div className="basic-content">
				<div className="titulo">
					<h1> {sessionStorage.getItem('atividadeNovo') == "true" ? "Cadastrar" : "Editar"} {estabelecimentoModalidade === 'Tarefa' ? 'tarefa' : 'atividade'}</h1>
				</div>

				<div className="atividade-box">
					
					<div className="back-button">
						<ReactTooltip effect="solid" />
						<Link to='/GerenciarAtividades'><i><FiChevronLeft data-tip="Voltar" /></i></Link>
					</div>

					<div className="atividade-linha">
						<div className="atividade-identificacao">
							<label htmlFor="identificacao">Descreva a {estabelecimentoModalidade === 'Tarefa' ? 'tarefa' : 'atividade'}</label>
							<textarea className="text-area-ident" type="longtext" aria-multiline="true" onChange={e => setIdentificacao(e.target.value)} value={identificacao} placeholder="Digite aqui" />
						</div>
						<div className="atividade-fontecircunstacia">

							<label htmlFor="fontecircunstacia">Fonte ou circunstância <FiTrash2 onClick={() => setModalFontes(true)} style={{ cursor: "pointer" }} data-tip='Excluir sugestões' data-for="aux" /></label>
							{DropFonte}
						</div>
						<div className="atividade-elaboradores">
							<label htmlFor="elaboradores">Elaboradores</label>
							{DropElaboradores}
						</div>
					</div>
					{FullLicensed ?
						<div className="atividade-linha">
							<div className="atividade-atv">
								<label htmlFor="">Detalhe da {estabelecimentoModalidade === 'Tarefa' ? 'tarefa' : 'atividade'}</label>
								<textarea className="atividade-atv-principal" aria-multiline="true" type="longtext" placeholder="Digite aqui"
									value={descricao}
									onChange={e => setDescricao(e.target.value)} />
							</div>
						</div>
						: null}

					<div className="medidas-1">
						<h3>Cargos</h3>
						<div>
							{ocupacoes.length ? ocupacoes.map(ocupacao => (
								<div key={ocupacao.id} className="ListaTrab-tarefa2">
									<ReactTooltip id={toString(ocupacao.id)} place="top" effect="solid" />
									<div>
										<h3 className="ListaTrab-nome2">{ocupacao.funcao}</h3>
									</div>
									<div className="ListaTrab-icones2">
										<i>
											<BiLink data-tip={"Gerenciar vínculos de trabalhadores expostos"} data-for={toString(ocupacao.id)}
												className="go-edit2"
												onClick={() => { sessionStorage.setItem('ocupacaoId', ocupacao.id); setModalShow2(true); sessionStorage.setItem('ocupacaoCargo', ocupacao.funcao)}}
											/>
										</i>
										<i>
											<BiUnlink data-tip={"Desvíncular cargo de todos os riscos associados"} data-for={toString(ocupacao.id)}
												className="go-edit2"
												onClick={() => {
													const r = window.confirm('Tem certeza que deseja desvincular esse registro?')
													if (r === true) {
														handleUnlinkOc(ocupacao.id, ocupacao.funcao, true);
													}
												}}
											/>
										</i>
										<i > <FiEdit data-tip={"Editar"} data-for={toString(ocupacao.id)} className="go-edit2" onClick={() => { sessionStorage.setItem('ocupacaoId', ocupacao.id); setModalShow(true) }} />  </i>
										<i>
											<FiTrash2 data-tip={"Excluir"} data-for={toString(ocupacao.id)}
												className="go-trash2"
												onClick={() => {
													const r = window.confirm('Tem certeza que deseja deletar esse registro?')
													if (r === true) {
														handleDeleteOc(ocupacao.id);
														handleUnlinkOc(ocupacao.id, ocupacao.funcao, false);
													}
												}} /> </i>
									</div>
								</div>
							)) : carregando ? null : <h3>Nenhum registro</h3>}
						</div>
						<div className="novo-elab">
							<button onClick={() => { sessionStorage.removeItem('ocupacaoId'); setModalShow(true) }}>Cadastrar cargo</button>
							<MyVerticallyCenteredModal ocupacoes={ocupacoes} show={modalShow} backdrop="static" onHide={() => { setModalShow(false); UpdateOcupacao() }} />
							<VinculaCargo show={modalShow2} backdrop="static" onHide={() => { setModalShow2(false); sessionStorage.removeItem('ocupacaoCargo') }} />
						</div>
					</div>

					<div className="atividade-cadastrar2">
						<button onClick={handleRegister}>{verifica ? "Salvar" : "Cadastrar"}</button>
					</div>
					<Loading loading={carregando} message='Carregando...' />

				</div>
			</div>

		</div>

	);
}

function MyVerticallyCenteredModal(props) {



	const ocupacaoId = sessionStorage.getItem('ocupacaoId')
	const tarefaId = sessionStorage.getItem('atividadeId')

	const [identificacao, setIdentificacao] = useState("");

	const [carregando, setCarregando] = useState(false);
	const [currentValues6, setCurrentValues6] = useState([]);
	const [searchQuery, setSearchQuery] = useState('')
	const [searchQuery2, setSearchQuery2] = useState('')
	const [cargos_cadastrados, setCargosCadastrados] = useState([])
	const [caracterizacao, setCaracterizacao] = useState([])
	const [opcoes_carac, setOpCarac] = useState([])
	const [modalCarac, setModalCarac] = useState(false)
	const { aviso } = React.useContext(Context);

	useEffect(() => {
		if (props.show) {

			setCarregando(true)
			setCurrentValues6([])
			setIdentificacao('')
			setSearchQuery2('')
			setCargosCadastrados([])

			async function initial() {
				var carac
				var c = await api.get('sugestoes/3')
				var aux = await api.get('ocupacao?tarefa_id=' + tarefaId + '&empresa_id=' + sessionStorage.getItem('empresaId'))
				var carg = []
				aux.data.resultado.forEach(item => {
					carg.push({ key: item.funcao, text: item.funcao, value: item.funcao })
				})

				setCargosCadastrados(carg)

				setOpCarac(c.data.resultado)
				try {


					if (ocupacaoId !== null) {

						api.get((`ocupacao?tarefa_id=${tarefaId}`)).then(response => {
							response.data.resultado.filter(ocupacao => ocupacao.id == ocupacaoId).map(ocupacao => { setIdentificacao(ocupacao.funcao); setSearchQuery2(ocupacao.funcao) })
						})

						await api.get(`oc_caracterizacao?ocupacao_id=${ocupacaoId}`).then(response => {
							let copy = Array.from([]);
							response.data.resultado.forEach(caracterizacao => {
								copy.push(caracterizacao.caracterizacao_atividade);
							})
							setCurrentValues6(copy);
							carac = copy
						})

						carac.forEach(item => {
							var verifica = false
							c.data.resultado.forEach(item2 => {
								if (item === item2.value) {
									verifica = true
								}
							})
							if (!verifica) {
								c.data.resultado.push({ key: item, text: item, value: item })
							}
						})

					} else {
						if (sessionStorage.getItem("AtividadeCargo") !== null) {
							setIdentificacao(sessionStorage.getItem("AtividadeCargo"))
							setSearchQuery2(sessionStorage.getItem("AtividadeCargo"))
						}
						if (sessionStorage.getItem('TarefaOcupacaoCaracterizacao') !== null) {
							aux = true
							carac = JSON.parse(sessionStorage.getItem('TarefaOcupacaoCaracterizacao'))
							setCurrentValues6(carac)
						}
					}
					setCaracterizacao(c.data.resultado)
				} catch (error) {

					aviso('error', error)
					console.log(error)

				} finally {

					setCarregando(false)
				}

			} initial()
		}

	}, [props.show, ocupacaoId])

	async function handleRegister(e) {
		e.preventDefault();

		const data = {
			funcao: identificacao
		};

		var verifica = []
		props.ocupacoes.forEach(ocupacao => {
			verifica.push(ocupacao.funcao)
		})

		if (identificacao === '' || currentValues6.length < 1 || verifica.includes(data.funcao)) {
			if(verifica.includes(data.funcao)){
				aviso('warning', "Cargo já cadastrado!")
			}else{
				aviso('warning', "Preencha todos os campos!")
			}
			
		} else {
			try {
				setCarregando(true);
				if (ocupacaoId === null) {
					await api.post('ocupacao?tarefa_id=' + tarefaId, data).then(response => sessionStorage.setItem('ocupacaoId', response.data.id_ocupacao))

					currentValues6.forEach(caracterizacao => {

						var v_carac = false
						opcoes_carac.forEach(item => {
							if (caracterizacao == item.value) {
								v_carac = true
							}
						})
						if (!v_carac) {

							var valor = {
								value: caracterizacao,
								text: caracterizacao
							}
							api.post("sugestoes/3", valor)
						}

						let data2 = {
							caracterizacao_atividade: caracterizacao
						}
						api.post('oc_caracterizacao?ocupacao_id=' + sessionStorage.getItem('ocupacaoId'), data2)
					})
					sessionStorage.setItem("TarefaOcupacaoCaracterizacao", JSON.stringify(currentValues6)) //Implementando sugestão dinâmica
					await api.get('ocupacao?tarefa_id=' + tarefaId)
					sessionStorage.setItem("AtividadeCargo", identificacao)
					aviso('success', "Cargo cadastrado com sucesso")
				} else {
					await api.delete('oc_caracterizacao/' + ocupacaoId)
					currentValues6.forEach(caracterizacao => {
						var v_carac = false

						opcoes_carac.forEach(item => {
							if (caracterizacao == item.value) {
								v_carac = true
							}
						})

						if (!v_carac) {

							var valor = {
								value: caracterizacao,
								text: caracterizacao
							}
							api.post("sugestoes/3", valor)
						}

						let data2 = {
							caracterizacao_atividade: caracterizacao
						}
						api.post('oc_caracterizacao?ocupacao_id=' + ocupacaoId, data2)
					})
					await api.patch('ocupacao/' + ocupacaoId, data)
					aviso('success', "Cargo salvo com sucesso")
				}

			} catch (error) {
				aviso('error', error);

			} finally {
				setCarregando(false)
				props.onHide()
				setIdentificacao('')
				setSearchQuery2('')
				setCurrentValues6([])
			}
		}

	}

	const handleChange = (e, { value }) => {
		setCurrentValues6(value);
		setSearchQuery('')
	}

	const handleSearchChange = (e, { searchQuery }) => {
		setSearchQuery(searchQuery)
	}

	const handleLabelClick = (e, { value }) => {
		setCurrentValues6(currentValues6.filter((item) => item != value))
		setSearchQuery(value)
	}

	const handleAddition = (e, { value }) => {
		const copy = Array.from(caracterizacao);
		copy.push({ key: value, text: value, value: value })
		setCaracterizacao(copy);
	}

	const handleChange2 = (e, { value }) => {
		setIdentificacao(value);
		setSearchQuery2(value);
	}

	const handleSearchChange2 = (e, { searchQuery }) => {
		setSearchQuery2(searchQuery)
	}


	const handleAddition2 = (e, { value }) => {
		const copy = Array.from(cargos_cadastrados);
		copy.push({ key: value, text: value, value: value })
		setCargosCadastrados(copy);
	}

	const DropCaracteriza = React.useMemo(() => (
		<Dropdown
			options={caracterizacao}
			placeholder={"Selecione múltiplos"}
			lazyLoad={true}
			search
			selection
			fluid
			multiple
			upward
			allowAdditions
			additionLabel="Adicionar "
			value={currentValues6}
			searchQuery={searchQuery}
			onAddItem={handleAddition}
			onChange={handleChange}
			onSearchChange={handleSearchChange}
			onLabelClick={handleLabelClick}
		/>
	), [caracterizacao, currentValues6, searchQuery]);

	const DropCargo = React.useMemo(() => (
		<Dropdown
			options={cargos_cadastrados}
			placeholder={"Selecione"}
			lazyLoad={true}
			search
			selection
			fluid
			upward
			allowAdditions
			additionLabel="Adicionar "
			value={identificacao}
			searchQuery={searchQuery2}
			onAddItem={handleAddition2}
			onChange={handleChange2}
			onSearchChange={handleSearchChange2}
		/>
	), [cargos_cadastrados, identificacao, searchQuery2]);

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>

			<ReactTooltip backgroundColor="#006ab4" className="tip" id="aux" effect="solid" multiline={true}></ReactTooltip>
			<RemoveCarac show={modalCarac} backdrop="static" onHide={() => { setModalCarac(false) }} />
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Cargos
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div>


					<div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
						<label>Cargo do trabalhor</label>
						{DropCargo}
					</div>

					<div className="atividade-caracterizacao">
						<label htmlFor="caracterizacao">Caracterização <FiTrash2 onClick={() => setModalCarac(true)} style={{ cursor: "pointer" }} data-tip='Excluir sugestões' data-for="aux" /></label>
						{DropCaracteriza}
					</div>


				</div>

			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleRegister} >{ocupacaoId === null ? 'Cadastrar' : 'Salvar'}</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}

function VinculaCargo(props) {

	const tarefaId = sessionStorage.getItem('atividadeId');
	const [riscos, setRiscos] = useState([]);
	const [carregando, setCarregando] = useState(false);
	const [funcao, setFuncao] = useState('');

	const { aviso } = React.useContext(Context);

	const CheckItem = (index) => {
		let b = Array.from(riscos);
		b[index].check = !b[index].check;
		if(b[index].check && b[index].qtd === 0){
			b[index].qtd = 1;
		}else{
			b[index].qtd = 0;
		}
		setRiscos(b);
	}

	const handleChangeQtd = (index, e) => {
		let b = Array.from(riscos);
		b[index].qtd = parseInt(e.target.value);
		setRiscos(b);
	  }

	useEffect(() => {
		if (props.show) {

			async function initial() {
				try {
					setCarregando(true)
					var cargo = sessionStorage.getItem('ocupacaoCargo') 
					await Promise.all([
						api.get(`risco?tarefa_id=${tarefaId}`),
						api.get(`trabalhadores_expostos?nome=${cargo}&tarefa_id=${tarefaId}`)
					]).then(response => {
						var riscos = []
						setFuncao(cargo)
						response[0].data.resultado.forEach(risco => {
							riscos.push({ id: risco.id, nome: risco.nome, fk_tarefa_id: risco.fk_tarefa_id, check: false, qtd: 0, funcao: sessionStorage.getItem('ocupacaoCargo'), update: false})
						})
						riscos.forEach(risco => {
							response[1].data.resultado.forEach(exposto => {
								if(exposto.risco_id === risco.id){
									risco.check = true;
									risco.qtd = exposto.qtd;
									risco.previewQtd = exposto.qtd;
									risco.update = true;
									risco.trabalhador_id = exposto.trabalhador_id;
								}
							})
						})
						setRiscos(riscos)
					})

				} catch (error) {
					console.log(error)
				} finally {
					setCarregando(false)
				}
			}
			initial()
		}

	}, [props.show])

	async function handleRegister(e) {
		e.preventDefault();
		try {
			if(riscos.length > 0){
				var toPost = riscos.filter(risco => risco.update === false && risco.check === true);
				var toUpdate = riscos.filter(risco => risco.update === true && risco.previewQtd !== risco.qtd);
				if(toPost.length > 0){
					await api.post(`trabalhadores_expostos?tipo=insert`, toPost)
				}

				if(toUpdate.length > 0){
					await api.post(`trabalhadores_expostos?tipo=update&`, toUpdate)
				}
				
				aviso('success', 'Trabalhadores vinculados ao(s) risco(s) com sucesso!');
			}
			setCarregando(true)
		} catch (error) {
			console.log(error);
		} finally {
			setCarregando(false);
			props.onHide();
		}
	}

	return (
		<Modal
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>

			<ReactTooltip backgroundColor="#006ab4" className="tip" id="aux" effect="solid" multiline={true}></ReactTooltip>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Gerenciar trabalhadores expostos aos riscos associados
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div>

					<div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
						<label>Cargo selecionado: <b>{funcao}</b></label>
					</div>

					<div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
						<label>Selecione os riscos que deseja vincular o cargo do trabalhador selecionado: </label>
					</div>

					<div className="trabalhador-box-scroll">
						{riscos.length !== 0 ? riscos.map((risco, index) => (
							<div key={risco.id}>
								<ReactTooltip id={toString(risco.id)} place="top" effect="solid"></ReactTooltip>
								<div>
									<a>{risco.nome}</a>
								</div>
								<div className="trabalhador-vincula">
									<div className='trabalhador-vincula1'>
										<Checkbox
											label= {!risco.check? 'Vincular': 'Desvincular'}
											onClick={() => CheckItem(index)}
											checked={risco.check}
										/>
									</div>
									{risco.check === true ?
										<div className='trabalhador-vincula2'>
											<label>Quantidade: </label>
											<input
												type='number'
												min={1}
												value={risco.qtd}
												onChange={(e) => handleChangeQtd(index, e)}
											/>
										</div> : null}
								</div>
							</div>)) : <a>Nenhum registro</a>}
					</div>

				</div>

			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleRegister} >Salvar</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}

function RemoveFontes(props) {


	const [carregando, setCarregando] = useState(false);
	const [opFontes, setOpFontes] = useState([]);
	const [searchQuery, setSearchQuery] = useState('')
	const [fontes, setFontes] = useState([])

	const { aviso } = React.useContext(Context);

	useEffect(() => {
		if (props.show) {

			setCarregando(true)
			setOpFontes([])
			setFontes([])

			async function initial() {
				try {
					var f = await api.get('sugestoes/4')
					setOpFontes(f.data.resultado.filter(item => item.fk_workspace_id !== -1))
				} catch (error) {
					aviso('error', error)
					console.log(error)
				} finally {
					setCarregando(false)
				}

			}
			initial()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show])

	async function handleDelete(e) {
		e.preventDefault();

		if (fontes.length < 0) {
			aviso('warning', "Preencha todos os campos!")
		} else {
			try {
				setCarregando(true);
				var lista = []
				fontes.forEach(item => {
					lista.push(opFontes.filter(item2 => item2.value === item)[0].key)
				})

				var data = {
					lista: lista
				}

				await api.post('sugestoesDelete/4', data)
				aviso('success', 'Removido com sucesso');

			} catch (error) {
				aviso('error', error);
			} finally {
				setCarregando(false)
				setSearchQuery('')
				props.onHide()
			}
		}

	}

	const handleChange = (e, { value }) => {
		setFontes(value);
		setSearchQuery('')
	}

	const handleSearchChange = (e, { searchQuery }) => {
		setSearchQuery(searchQuery)
	}

	const handleLabelClick = (e, { value }) => {
		setFontes(fontes.filter((item) => item !== value))
		setSearchQuery(value)
	}


	const DropFonte = React.useMemo(() => (
		<Dropdown
			options={opFontes}
			placeholder={"Selecione múltiplos"}
			lazyLoad={true}
			search
			selection
			fluid
			multiple
			upward
			value={fontes}
			searchQuery={searchQuery}
			onChange={handleChange}
			onSearchChange={handleSearchChange}
			onLabelClick={handleLabelClick}
		/>
	), [fontes, opFontes, searchQuery]);


	return (
		<Modal
			{...props}
			size="sm"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Excluir sugestões
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div>

					<div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
						<label>Fonte ou circunstância</label>
						{DropFonte}
					</div>

				</div>

			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleDelete} >Excluir</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}

function RemoveCarac(props) {


	const [carregando, setCarregando] = useState(false);
	const [opCarac, setopCarac] = useState([]);
	const [searchQuery, setSearchQuery] = useState('')
	const [carac, setCarac] = useState([])

	const { aviso } = React.useContext(Context);

	useEffect(() => {
		if (props.show) {

			setCarregando(true)
			setopCarac([])
			setCarac([])

			async function initial() {
				try {
					var f = await api.get('sugestoes/3')
					setopCarac(f.data.resultado.filter(item => item.fk_workspace_id !== -1))
				} catch (error) {
					aviso('error', error)
					console.log(error)
				} finally {
					setCarregando(false)
				}

			}
			initial()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show])

	async function handleDelete(e) {
		e.preventDefault();

		if (carac.length < 0) {
			aviso('warning', "Preencha todos os campos!")
		} else {
			try {
				setCarregando(true);
				var lista = []
				carac.forEach(item => {
					lista.push(opCarac.filter(item2 => item2.value === item)[0].key)
				})

				var data = {
					lista: lista
				}

				await api.post('sugestoesDelete/3', data)
				aviso('success', 'Removido com sucesso');

			} catch (error) {
				aviso('error', error);
			} finally {
				setCarregando(false)
				setSearchQuery('')
				props.onHide()
			}
		}

	}

	const handleChange = (e, { value }) => {
		setCarac(value);
		setSearchQuery('')
	}

	const handleSearchChange = (e, { searchQuery }) => {
		setSearchQuery(searchQuery)
	}

	const handleLabelClick = (e, { value }) => {
		setCarac(carac.filter((item) => item !== value))
		setSearchQuery(value)
	}


	const DropCarac = React.useMemo(() => (
		<Dropdown
			options={opCarac}
			placeholder={"Selecione múltiplos"}
			lazyLoad={true}
			search
			selection
			fluid
			multiple
			upward
			value={carac}
			searchQuery={searchQuery}
			onChange={handleChange}
			onSearchChange={handleSearchChange}
			onLabelClick={handleLabelClick}
		/>
	), [carac, opCarac, searchQuery]);


	return (
		<Modal
			{...props}
			size="sm"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Excluir sugestões
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div>

					<div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
						<label>Caracterização</label>
						{DropCarac}
					</div>

				</div>

			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleDelete} >Excluir</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}