import React from 'react'
import ReactPlayer from 'react-player'

import './styles.css';
import video17 from '../../assets/laudo6.mp4'
import video16 from '../../assets/laudo5.mp4'
import video15 from '../../assets/laudo4.mp4'
import video14 from '../../assets/laudo3.mp4'
import video13 from '../../assets/laudo2.mp4'
import video12 from '../../assets/laudo1.mp4'
import video11 from '../../assets/Cadastro de Plano de Ação no PGRSmart.mp4'
import video10 from '../../assets/Cadastro de Risco no PGRSmart.mp4'
import video9 from '../../assets/Cadastro de Atividade no PGRSmart.mp4'
import video8 from '../../assets/Cadastro de Unidade, Setor, GHE no PGRSmart.mp4'
import video7 from '../../assets/Cadastro de Empresa do PGRSmart.mp4'
import video6 from '../../assets/Passo 3 - Avaliação, Classificação e Controle.mp4'
import video5 from '../../assets/Passo 2 - Perigos e Riscos.mp4'
import video4 from '../../assets/Passo 1 - Como identificar o Trabalhador.mp4'
import video3 from '../../assets/Navegando pelos campos do Inventario de Riscos do PGRSmart.mp4'
import video2 from '../../assets/Navegando na Estrutura do PGR Smart.mp4'
import video1 from '../../assets/Facilidades do PGRSmart.mp4'



import { Menu } from '../../global.js'


export default function Videos() {


    return (

        <div>
            <Menu />
            <div className="video-basic-content">

                <div className="titulo">
                    <h1>Vídeos</h1>
                </div>
                <div className="video-box">
                    <div className="login-content-video">

                        <div className="video-pgr">
                            <div className="video-item">
                                <h3 style={{textAlign:'center'}}>Video 1- Facilidades do PGR Smart</h3>
                                <ReactPlayer controls={true} light={true}  url={video1} width='320px' height='200px'/>
                            </div>
                            <div className="video-item">
                                <h3 style={{textAlign:'center'}}>Video 2 - Navegando na Estrutura do PGR Smart</h3>
                                <ReactPlayer controls={true} light={true} url={video2} width='320px' height='200px'/>
                            </div>
                            <div className="video-item">
                                <h3 style={{textAlign:'center'}}>Video 3 - Navegando pelos campos do Inventario de Riscos do PGR Smart</h3>
                                <ReactPlayer controls={true} light={true} url={video3} width='320px' height='200px'/>
                            </div>

                        </div>

                        <div className="video-pgr">
                            <div className="video-item">
                                <h3 style={{textAlign:'center'}}>Video 4- Como identificar o trabalhador</h3>
                                <ReactPlayer controls={true} light={true} url={video4} width='320px' height='200px'/>
                            </div>
                            <div className="video-item">
                                <h3 style={{textAlign:'center'}}>Video 5 - Perigos e Riscos</h3>
                                <ReactPlayer controls={true} light={true} url={video5} width='320px' height='200px'/>
                            </div>
                            <div className="video-item">
                                <h3 style={{textAlign:'center'}}>Video 6 - Avaliação, Classificação e Controle</h3>
                                <ReactPlayer controls={true} light={true} url={video6} width='320px' height='200px'/>
                            </div>

                        </div>
                        <div className="video-pgr">
                            
                            <div className="video-item">
                                <h3 style={{textAlign:'center'}}>Video 7 - Cadastro de empresa</h3>
                                <ReactPlayer controls={true} light={true} url={video7} width='320px'/>
                            </div>
                            <div className="video-item">
                                <h3 style={{textAlign:'center'}}>Video 8 - Cadastro de unidade, setor e GHE</h3>
                                <ReactPlayer controls={true} light={true} url={video8} width='320px'/>
                            </div>
                            <div className="video-item">
                                <h3 style={{textAlign:'center'}}>Video 9 - Cadastro de atividade</h3>
                                <ReactPlayer controls={true} light={true} url={video9} width='320px'/>
                            </div>

                        </div>
                        <div className="video-pgr">
                            
                            <div className="video-item">
                                <h3 style={{textAlign:'center'}}>Video 10 - Cadastro de risco</h3>
                                <ReactPlayer controls={true} light={true} url={video10} width='320px' />
                            </div>
                            <div className="video-item">
                                <h3 style={{textAlign:'center'}}>Video 11 - Cadastro de plano de ação</h3>
                                <ReactPlayer controls={true} light={true} url={video11} width='320px' />
                            </div>
                            <div className="video-item">
                                <h3 style={{textAlign:'center'}}>Video 12 - Elaboração laudo de calor</h3>
                                <ReactPlayer controls={true} light={true} url={video12} width='320px' />
                            </div>
                        </div>
                        <div className="video-pgr">
                            
                            <div className="video-item">
                                <h3 style={{textAlign:'center'}}>Video 13 - Elaboração de laudo de vibração</h3>
                                <ReactPlayer controls={true} light={true} url={video13} width='320px' />
                            </div>
                            <div className="video-item">
                                <h3 style={{textAlign:'center'}}>Video 14 - Elaboração de laudos químicos (Qualitativo)</h3>
                                <ReactPlayer controls={true} light={true} url={video14} width='320px' />
                            </div>
                            <div className="video-item">
                                <h3 style={{textAlign:'center'}}>Video 15 - Elaboração de laudos químicos (Quantitativo)</h3>
                                <ReactPlayer controls={true} light={true} url={video15} width='320px' />
                            </div>
                        </div>
                        <div className="video-pgr">
                            
                            <div className="video-item">
                                <h3 style={{textAlign:'center'}}>Video 16 - Configuração do laudo</h3>
                                <ReactPlayer controls={true} light={true} url={video16} width='320px' />
                            </div>
                            <div className="video-item">
                                <h3 style={{textAlign:'center'}}>Video 17 - Como baixar um laudo</h3>
                                <ReactPlayer controls={true} light={true} url={video17} width='320px' />
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>

    );
}
