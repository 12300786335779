import React, { useState, useEffect } from 'react';
import { FiInfo, FiEdit, FiTrash2, FiSearch, FiChevronLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'; //usado para linkar outras paginas.
import api from '../../../services/api'
import { Menu } from '../../../global.js'
import { Modal, Button } from 'react-bootstrap'

import './styles.css';
import './stylesPlano.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from '../../Loading'
import { Context } from '../../../Context/AuthContext';
import ReactTooltip from 'react-tooltip';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import br from 'date-fns/locale/pt-BR';
import { Dropdown, Checkbox} from 'semantic-ui-react'


export default function CadastrarEmpresa() {

	const [elaboradores, setElaboradores] = useState([]);

	const [modalShow, setModalShow] = React.useState(false);
	const [modalShow2, setModalShow2] = React.useState(false);
	const [modalEditShow, setModalEditShow] = React.useState(false);
	const [id, setId] = useState('')

	const [razao_social, setRazao] = useState('')
	const [responsavel, setResponsavel] = useState('')
	const [responsavel_cargo, setResponsavelCargo] = useState('')
	const [tipo, setTipo] = useState('')
	const [inscricao, setInscricao] = useState('')
	const [cnae, setCnae] = useState('')
	const [cep, setCep] = useState('')
	const [municipio, setMunicipio] = useState('')
	const [endereco, setEndereco] = useState('')
	const [bairro, setBairro] = useState('')
	const [telefone, setTelefone] = useState('')
	const [uf, setUf] = useState('')
	const [atividade_principal, setAtividade] = useState('')
	const [grau_risco, setGrau] = useState(0)
	const [n_funcionarios, setFuncionarios] = useState(0)
	const [trabalho_nome, setTrabNome] = useState('')
	const [trabalho_descricao, setTrabDesc] = useState('')
	const [trabalho_cnpj, setTrabCnpj] = useState('')
	const [trabalho_risco, setTrabRisco] = useState('')
	const [trabalho_endereco, setTrabEnd] = useState('')
	const [frente_trabalho, setFrente] = useState(0)
	const [eletricidade, setEletricidade] = useState(0)
	const [altura, setAltura] = useState(0)
	const [prazo, setPrazo] = useState('')
	const [max_funcionario, setMaxFuncionario] = useState('')
	const [horario, setHorario] = useState('')
	const [contrato, setContrato] = useState('')
	const [planos, setPlanos] = useState([])
	const [abrir_plano, setAbrirPlano] = useState('')
	const [ordem, setOrdem] = useState(null)

	const history = useHistory();

	const [carregando, setCarregando] = useState(false)

	const { aviso } = React.useContext(Context);

	registerLocale('pt-BR', br)

	async function UpdateElaborador() {
		const id = sessionStorage.getItem('empresaId')
		try {
			setCarregando(true)
			await api.get(`elaborador?empresa_id=${id}`).then(res => {
				setElaboradores(res.data.resultado)
			})

		} catch (error) {

			aviso('error', error)

		} finally {

			setCarregando(false)
		}
	}

	async function UpdatePlanos() {
		const id = sessionStorage.getItem('empresaId')
		try {
			setCarregando(true)
			await api.get(`plano_acao_geral?empresa_id=${id}`).then(res => {
				setPlanos(res.data.resultado)
			})

		} catch (error) {

			aviso('error', error)

		} finally {

			setCarregando(false)
		}
	}

	async function handleDeletePlano(id) {
		try {
			await api.delete(`plano_acao_geral/${id}`)
			setPlanos(planos.filter(plano => plano.id !== id))
			aviso('success', 'Plano removido com sucesso')
		} catch (error) {
			aviso('error', error)
		}
	}

	useEffect(() => {

		async function teste() {

			if (sessionStorage.getItem('empresaId') !== null) {
				try {

					setCarregando(true)
					setId(sessionStorage.getItem('empresaId'))
					const response = await api.get('empresa')

					let emp = (response.data.resultado.filter(empresa => empresa.id == sessionStorage.getItem('empresaId')))
					emp.forEach(item => {
						setRazao(item.razao_social)
						setResponsavel(item.responsavel)
						setResponsavelCargo(item.responsavel_cargo)
						setTipo(item.tipo)
						setInscricao(item.inscricao)
						setCnae(item.cnae)
						setCep(item.cep)
						setMunicipio(item.municipio)
						setEndereco(item.endereco)
						setBairro(item.bairro)
						setTelefone(item.telefone)
						setUf(item.uf)
						setAtividade(item.atividade_principal)
						setGrau(item.grau_risco)
						setFuncionarios(item.n_funcionarios)
						setAltura(item.altura)
						setEletricidade(item.eletricidade)
						setPrazo(item.prazo)
						setTrabDesc(item.trabalho_descricao)
						setTrabNome(item.trabalho_nome)
						setTrabRisco(item.trabalho_risco)
						setTrabEnd(item.trabalho_endereco)
						setTrabCnpj(item.trabalho_cnpj)
						setMaxFuncionario(item.max_funcionario)
						setHorario(item.horario)
						setFrente(item.frente_trabalho)
						setContrato(item.contrato)
						setAbrirPlano(item.abrir_plano)
						setOrdem(item.ordem)
					})

					UpdateElaborador();
					UpdatePlanos();

					if(sessionStorage.getItem('dashboardPlano') === "true"){
						window.scrollTo(0,document.body.scrollHeight);
						setModalShow2(true)
						setAbrirPlano('Sim')
					}

				} catch (error) {

					aviso('error', error)

				} finally {

					setCarregando(false)
				}

			} else {

				const data = {
					razao_social,
					responsavel,
					responsavel_cargo,
					tipo,
					inscricao,
					cnae,
					cep,
					municipio,
					endereco,
					bairro,
					telefone,
					uf,
					atividade_principal,
					grau_risco,
					n_funcionarios,
					trabalho_nome,
					trabalho_descricao,
					trabalho_risco,
					trabalho_cnpj,
					horario,
					eletricidade,
					altura,
					prazo,
					frente_trabalho,
					trabalho_endereco,
					max_funcionario,
					contrato,
					ordem,
					abrir_plano
				};

				try {
					await api.post('empresa?workspace=1', data).then(response => {
						setId(response.data.id_empresa)
						sessionStorage.setItem('empresaId',response.data.id_empresa)
					})

				} catch (error) {
					aviso("error", error)
				}

			}

		} teste();

	}, []);

	async function handleRegister(e) {
		e.preventDefault();

		var data

		if (frente_trabalho == 0) {
			data = {
				razao_social,
				responsavel,
				responsavel_cargo,
				tipo,
				inscricao,
				cnae,
				cep,
				municipio,
				endereco,
				bairro,
				telefone,
				uf,
				atividade_principal,
				grau_risco,
				n_funcionarios,
				abrir_plano,
				ordem
			}
		} else {
			data = {
				razao_social,
				responsavel,
				responsavel_cargo,
				tipo,
				inscricao,
				cnae,
				cep,
				municipio,
				endereco,
				bairro,
				telefone,
				uf,
				atividade_principal,
				grau_risco,
				n_funcionarios,
				trabalho_nome,
				trabalho_descricao,
				trabalho_risco,
				trabalho_cnpj,
				horario,
				eletricidade,
				altura,
				prazo,
				frente_trabalho,
				trabalho_endereco,
				max_funcionario,
				contrato,
				abrir_plano,
				ordem
			}
		}

		if (data.atividade_principal === '' || data.tipo === '' || data.inscricao === '' || data.cnae === '' || data.municipio === '' || data.cep === '' || data.telefone === '' || data.uf === '' || data.atividade_principal === '' || data.grau_risco === 0 || data.endereco == '' || data.bairro == '' || data.responsavel == '' || data.responsavel_cargo == 0 || elaboradores.length == 0 || data.cep == '' || data.razao_social == '' || data.n_funcionarios == 0 || data.n_funcionarios == '' || (abrir_plano == "Sim" && planos.length == 0)) {

			if(abrir_plano == "Sim" && planos.length == 0){
				aviso("warning", "Cadastre pelo menos um plano de ação!")
			}else{
				aviso("warning", "Preencha todos os campos!")
			}
			

		} else {

			try {
				setCarregando(true)
				await api.patch(`empresa/${id}`, data);
				if(sessionStorage.getItem('empresaNovo') == 'true'){
					aviso('success', 'Empresa cadastrada com sucesso')
				}else{
					aviso('success', 'Empresa atualizada com sucesso')
				}
			} catch (error) {
				aviso('error', error)
			} finally {
				setCarregando(false)
				history.push('GerenciarEmpresas')
			}
		}

	}

	async function handleDeleteElaborador(id) {
		try {
			setCarregando(true)
			await api.delete(`elaborador/${id}`)
			setElaboradores(elaboradores.filter(elaborador => elaborador.id !== id))
			aviso('success', 'Elaborador removido com sucesso')
		} catch (error) {
			aviso('error', error)
		} finally {
			setCarregando(false)
		}
	}


	return (
		<div>
			<Menu />
			<ReactTooltip effect="solid" />
			<Loading loading={carregando} message='Carregando...' />
			<div className="basic-content">

				<div className="titulo">
					<h1 className="titulo">{sessionStorage.getItem('empresaNovo') == 'true'? 'Cadastrar empresa':'Editar empresa'}</h1>
				</div>
				<div className="cadastrar-box">
					<div>
						
					<div className="back-button">
						<Link to='/GerenciarEmpresas'><i><FiChevronLeft data-tip="Voltar" /></i></Link>
					</div>
						<div className="linha-start">

							<div className="rSocial">
								<label htmlFor="razao-social">Razão Social</label>
								<input
									value={razao_social}
									onChange={e => setRazao(e.target.value)}
									type="text" id="razao-social"
									placeholder="Digite aqui" />
							</div>
							<div className="tipoInscricao">
								<label>Tipo de inscrição</label>
								<select value={tipo} onChange={e => setTipo(e.target.value)}>
									<option value="" disabled>Selecione</option>
									<option value="1 - CNPJ">1 - CNPJ</option>
									<option value="2 - CPF">2 - CPF</option>
									<option value="3 - CAEPF">3 - CAEPF</option>
									<option value="4 - CNO">4 - CNO</option>
									<option value="5 - CGC">5 - CGC</option>
									<option value="6 - CEI">6 - CEI</option>
								</select>

							</div>

							<div hidden={tipo == "" ? true : false} className="cnpj">
								<label htmlFor="CNPJ">{tipo.split('- ')[1]}</label>
								<input value={inscricao} onChange={e => setInscricao(e.target.value)} type="text" placeholder={"Digite o " + tipo.split('- ')[1]} />
							</div>
						</div>

						<div className="linha-1">
							<div className="tel">
								<label>Telefone</label>
								<input
									value={telefone}
									onChange={e => setTelefone(e.target.value)}
									type="text"
									placeholder="Digite aqui" />
							</div>

							<div className="cep">
								<label>CEP</label>
								<input
									value={cep}
									onChange={e => setCep(e.target.value)}
									type="text"
									placeholder="Digite aqui" />
							</div>

							<div className="municipio">
								<label>Município</label>
								<input
									value={municipio}
									onChange={e => setMunicipio(e.target.value)}
									type="text"
									placeholder="Digite aqui" />
							</div>

							<div className="uf">
								<label>UF</label>
								<select value={uf} onChange={e => setUf(e.target.value)} name="select">
									<option value='' disabled>Selecione</option>
									<option value="AC">AC</option>
									<option value="AL">AL</option>
									<option value="AP">AP</option>
									<option value="AM">AM</option>
									<option value="BA">BA</option>
									<option value="CE">CE</option>
									<option value="DF">DF</option>
									<option value="ES">ES</option>
									<option value="GO">GO</option>
									<option value="MA">MA</option>
									<option value="MT">MT</option>
									<option value="MS">MS</option>
									<option value="MG">MG</option>
									<option value="PA">PA</option>
									<option value="PB">PB</option>
									<option value="PR">PR</option>
									<option value="PE">PE</option>
									<option value="PI">PI</option>
									<option value="RJ">RJ</option>
									<option value="RN">RN</option>
									<option value="RS">RS</option>
									<option value="RO">RO</option>
									<option value="RR">RR</option>
									<option value="SC">SC</option>
									<option value="SP">SP</option>
									<option value="SE">SE</option>
									<option value="TO">TO</option>
								</select>
							</div>
						</div>

						<div className="linha-1">

							<div className="nFuncionarios">
								<label>Número de Funcionários</label>
								<input
									value={n_funcionarios}
									onChange={e => setFuncionarios(e.target.value)}
									type="number"
									min={0}
									placeholder="Digite aqui" />
							</div>

							<div className="nEndereco">
								<label>Endereço</label>
								<input
									value={endereco}
									onChange={e => setEndereco(e.target.value)}
									type="text"
									min="0"
									placeholder="Digite aqui" />
							</div>

							<div className="nBairro">
								<label>Bairro</label>
								<input
									value={bairro}
									onChange={e => setBairro(e.target.value)}
									type="text"
									placeholder="Digite aqui" />
							</div>
						</div>

						<div className="linha-1">

							<div className="nResponsavel">
								<label>Responsável legal</label>
								<input
									value={responsavel}
									onChange={e => setResponsavel(e.target.value)}
									type="text"
									placeholder="Digite aqui" />
							</div>

							<div className="nCargo">
								<label>Cargo</label>
								<input
									value={responsavel_cargo}
									onChange={e => setResponsavelCargo(e.target.value)}
									type="text"
									placeholder="Digite aqui" />
							</div>

							<div className="CNAE">
								<label>CNAE</label>
								<input
									value={cnae}
									onChange={e => setCnae(e.target.value)}
									type="text"
									placeholder="Digite aqui" />
							</div>

							<div className="grau">
								<label>Grau de risco</label>
								<select value={grau_risco} onChange={e => setGrau(e.target.value)} name="select">
									<option value={0} disabled>Selecione</option>
									<option value={1}>1</option>
									<option value={2}>2</option>
									<option value={3}>3</option>
									<option value={4}>4</option>
								</select>
							</div>

						</div>

						<div className="linha-1">
							<div className="atv">
								<label>Atividade principal</label>
								<textarea
									value={atividade_principal}
									onChange={e => setAtividade(e.target.value)}
									className="atv-principal"
									aria-multiline="true"
									type="longtext"
									placeholder="Digite aqui" />
							</div>
						</div>

						<div className="linha-1">

							<div className="tipoInscricao2">
								<label>Frente de trabalho</label>
								<select value={frente_trabalho} onChange={e => setFrente(e.target.value)}>
									<option value={0}>Não</option>
									<option value={1}>Sim</option>
								</select>
							</div>

							{frente_trabalho != 0 ?
								<div className="nEstabelecimento">
									<label>Nome do estabelecimento</label>
									<input
										value={trabalho_nome}
										onChange={e => setTrabNome(e.target.value)}
										type="text"
										placeholder="Digite aqui" />
								</div> : null}

							{frente_trabalho != 0 ?
								<div className="nDescricao">
									<label htmlFor="">Descrição da atividade da empresa</label>
									<input
										value={trabalho_descricao}
										onChange={e => setTrabDesc(e.target.value)}
										placeholder="Digite aqui" />
								</div> : null}

							{frente_trabalho != 0 ?
								<div className="nCNPJ">
									<label>CNPJ do estabelecimento</label>
									<input
										value={trabalho_cnpj}
										onChange={e => setTrabCnpj(e.target.value)}
										type="text"
										placeholder="Digite aqui" />
								</div> : null}

						</div>

						{frente_trabalho != 0 ?
							<div>
								<div className="linha-1">

									<div className="nEnderecoEstabelecimento">
										<label>Endereço do estabelecimento</label>
										<input
											value={trabalho_endereco}
											onChange={e => setTrabEnd(e.target.value)}
											type="text"
											placeholder="Digite aqui" />
									</div>

									<div className="nContrato">
										<label>Contrato</label>
										<input
											value={contrato}
											onChange={e => setContrato(e.target.value)}
											type="text"
											placeholder="Digite aqui" />
									</div>

									<div className="nHorario">
										<label>Horário de trabalho</label>
										<input
											value={horario}
											onChange={e => setHorario(e.target.value)}
											type="text"
											placeholder="Digite aqui" />
									</div>

									<div className="nPrazo">
										<label>Prazo de execução</label>
										<input
											value={prazo}
											onChange={e => setPrazo(e.target.value)}
											type="text"
											placeholder="Digite aqui" />
									</div>

								</div>

								<div className="linha-1">

									<div className="nMaxFunc">
										<label>Número máximo de funcionários previstos</label>
										<input
											value={max_funcionario}
											onChange={e => setMaxFuncionario(e.target.value)}
											type="text"
											placeholder="Digite aqui" />
									</div>

									<div className="nAltura">
										<label>Trabalho em altura</label>
										<select value={altura} onChange={e => setAltura(e.target.value)}>
											<option value={0}>Não</option>
											<option value={1}>Sim</option>
										</select>
									</div>

									<div className="nEletricidade">
										<label>Trabalho com eletricidade</label>
										<select value={eletricidade} onChange={e => setEletricidade(e.target.value)}>
											<option value={0}>Não</option>
											<option value={1}>Sim</option>
										</select>
									</div>

									<div className="nGrau">
										<label>Grau de risco</label>
										<select value={trabalho_risco} onChange={e => setTrabRisco(e.target.value)} name="select">
											<option value={'0'} disabled>Selecione</option>
											<option value={'1'}>1</option>
											<option value={'2'}>2</option>
											<option value={'3'}>3</option>
											<option value={'4'}>4</option>
										</select>
									</div>

								</div>
							</div> : null}

						<div className="elaboradores">
							<h2>Elaboradores:</h2>
							<div>

								{elaboradores.length !== 0 ? elaboradores.map(elaborador => (
									<div key={elaborador.id} className="elab-list">
										<ReactTooltip id={toString(elaborador.id)} place="top" effect="solid" />
										<div>
											<h3 className="elab-list-nome">{elaborador.nome}</h3>
										</div>
										<div className="ListaTrab-icones2">
											<i > <FiEdit data-tip={"Editar"} data-for={toString(elaborador.id)} className="edit" onClick={() => { sessionStorage.setItem('elab_id', elaborador.id); setModalEditShow(true) }} />  </i>
											<MyVerticallyCenteredModal2 show={modalEditShow} onHide={() => { setModalEditShow(false); UpdateElaborador() }} backdrop="static" />
											<i>
												<FiTrash2 data-tip={"Excluir"} data-for={toString(elaborador.id) + elaborador.nome}
													className="trash"
													onClick={() => {
														const r = window.confirm('Tem certeza que deseja deletar esse trabalhador?')
														if (r === true) {
															handleDeleteElaborador(elaborador.id);
														}
													}} />

											</i>
										</div>

									</div>
								)) : <h3>Nenhum registro</h3>}
							</div>
							<div className="novo-elab">
								<button onClick={() => { setModalShow(true) }}>Cadastrar novo elaborador</button>
								<MyVerticallyCenteredModal show={modalShow} onHide={() => { setModalShow(false); UpdateElaborador() }} backdrop="static" />
							</div>
						</div>

					<div className="tipoInscricao2">
						<label style={{marginTop:'15px'}}>Realizar plano de ação?</label>
						<select value={abrir_plano} onChange={e => setAbrirPlano(e.target.value)}>
							<option value='' disabled>Selecione</option>
							<option value="Não">Não</option>
							<option value="Sim">Sim</option>
						</select>
					</div>

					{abrir_plano === 'Sim' ?

						<div className="medidas-1">
							<h3>Planos de ações</h3>
							<div>
								{planos.length ? planos.map(plano => (
									
									<div key={plano.id} className="elab-list">
										<ReactTooltip id={toString(plano.id)} place="top" effect="solid"/>

										<div>
											<h3 className="ListaPlano-nome2">{plano.o_que}</h3>
										</div>
										<div className="ListaTrab-icones2">
											<i >
												<FiEdit data-tip={"Editar"} data-for={toString(plano.id)} className="go-edit2" onClick={() => {
													sessionStorage.setItem('planoId', plano.id)
													setModalShow2(true)
												}} />
											</i>
											<i>
												<FiTrash2 data-tip={"Excluir"} data-for={toString(plano.id)}
													className="go-trash2"
													onClick={() => {
														const r = window.confirm('Tem certeza que deseja deletar esse plano?')
														if (r === true) {
															handleDeletePlano(plano.id);
														}
													}} />

											</i>
										</div>
									</div>
								)) : carregando ? null : <h3>Nenhum registro</h3>}
							</div>
							<div className="novo-elab">
								<button onClick={() => { sessionStorage.setItem("planoId", 0); setModalShow2(true) }}> Cadastrar novo plano de ação</button>
								<PlanoAcao show={modalShow2} backdrop="static" onHide={() => {
									setModalShow2(false);
									UpdatePlanos();
									if(sessionStorage.getItem('moveTop') === 'true'){
										window.scrollTo(0, 0);
										sessionStorage.removeItem('moveTop')
									}
									if(sessionStorage.getItem('dashboardPlano') == 'true'){
										sessionStorage.removeItem('dashboardPlano')
									}  
								}
							} 
							/>
							</div>
						</div>
						:
						null
					}

						<div className="cadastrar">
							<button onClick={handleRegister} type="submit">{sessionStorage.getItem('empresaNovo') == 'true'? 'Cadastrar':"Salvar"}</button>
						</div>

					</div>


				</div>
			</div >

		</div>

	);

}

function MyVerticallyCenteredModal(props) {

	const id = sessionStorage.getItem('empresaId')

	const [nome, setNome] = useState('')
	const [cargo, setCargo] = useState('')
	const [orgao_classe, setOrgao_classe] = useState('')
	const [numero_inscricao, setNumero_inscricao] = useState('')
	const history = useHistory();
	const { aviso } = React.useContext(Context);


	async function handleElaborador(e) {
		/* e.preventDefault(); */

		const data = {
			nome,
			cargo,
			orgao_classe,
			numero_inscricao
		};


		if (data.nome === '' || data.cargo === '' || data.orgao_classe === '' || data.numero_inscricao === '') {

			aviso('warning', "Preencha todos os campos!")

		} else {

			try {
				await api.post(`elaborador?empresa_id=${id}`, data);
				aviso('success', 'Elaborador cadastrado com sucesso')
				props.onHide();
				setNome('');
				setCargo('');
				setOrgao_classe('');
				setNumero_inscricao('');

			} catch (error) {
				aviso('error', error)
				aviso('error', error)
			}
		}

	}


	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Cadastro de elaborador
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="empresa-modal">
					<div className="empresa-modal-box-nome">
						<label htmlFor="empresa-modal-nome">Nome</label>
						<input
							value={nome}
							onChange={e => setNome(e.target.value)}
							type="text"
							id="empresa-modal-nome"
							placeholder="Digite aqui" />
					</div>
					<div className="empresa-modal-box-cargo">
						<label htmlFor="empresa-modal-cargo">Cargo</label>
						<input
							value={cargo}
							onChange={e => setCargo(e.target.value)}
							type="text"
							id="empresa-modal-cargo"
							placeholder="Digite aqui" />
					</div>
					<div className="empresa-modal-box-orgao">
						<label htmlFor="empresa-modal-orgao">Orgão</label>
						<input
							value={orgao_classe}
							onChange={e => setOrgao_classe(e.target.value)}
							type="text"
							id="empresa-modal-orgao"
							placeholder="Digite aqui" />
					</div>
					<div className="empresa-modal-box-num-orgao">
						<label htmlFor="empresa-modal-num-orgao">Nº de inscrição no orgão de classe</label>
						<input
							value={numero_inscricao}
							onChange={e => setNumero_inscricao(e.target.value)}
							type="text"
							id="empresa-modal-num-orgao"
							placeholder="Digite aqui" />
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleElaborador} >Adicionar</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}

function MyVerticallyCenteredModal2(props) {

	const id = sessionStorage.getItem('empresaId')
	const elab_id = sessionStorage.getItem('elab_id')


	const [nome, setNome] = useState('')
	const [cargo, setCargo] = useState('')
	const [orgao_classe, setOrgao_classe] = useState('')
	const [numero_inscricao, setNumero_inscricao] = useState('')
	const [elaboradores, setElaboradores] = useState([]);
	const { aviso } = React.useContext(Context);

	const history = useHistory();

	useEffect(() => {

		async function teste() {
			try {


				const response = await api.get(`elaborador?empresa_id=${id}`)
				setElaboradores(response.data.resultado)
				let elab = (response.data.resultado.filter(elaborador => elaborador.id == elab_id))
				elab.map(item => {
					setCargo(item.cargo);
					setNome(item.nome);
					setOrgao_classe(item.orgao_classe);
					setNumero_inscricao(item.numero_inscricao);
				})


			} catch (error) {
				aviso('error', error)
			}

		} teste();



	}, [elab_id]);


	async function handleElaborador(e) {
		/* e.preventDefault(); */

		const data = {
			nome,
			cargo,
			orgao_classe,
			numero_inscricao
		};


		if (data.nome === '' || data.cargo === '' || data.orgao_classe === '' || data.numero_inscricao === '') {

			aviso('warning', "Preencha todos os campos!")

		} else {

			try {
				await api.patch(`elaborador/${elab_id}`, data);
				history.push('CadastrarEmpresa')
				aviso('success', 'Elaborador atualizado com sucesso')
				props.onHide();

			} catch (error) {
				aviso('error', error)
				aviso('error', error)
			}
		}

	}


	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Cadastro de elaborador
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="empresa-modal">
					<div className="empresa-modal-box-nome">
						<label htmlFor="e-empresa-modal-nome">Nome</label>
						<input
							value={nome}
							onChange={e => setNome(e.target.value)}
							type="text"
							id="e-empresa-modal-nome"
							placeholder="Digite aqui" />
					</div>
					<div className="empresa-modal-box-cargo">
						<label htmlFor="e-empresa-modal-cargo">Cargo</label>
						<input
							value={cargo}
							onChange={e => setCargo(e.target.value)}
							type="text"
							id="e-empresa-modal-cargo"
							placeholder="Digite aqui" />
					</div>
					<div className="empresa-modal-box-orgao">
						<label htmlFor="e-empresa-modal-orgao">Orgão</label>
						<input
							value={orgao_classe}
							onChange={e => setOrgao_classe(e.target.value)}
							type="text"
							id="e-empresa-modal-orgao"
							placeholder="Digite aqui" />
					</div>
					<div className="empresa-modal-box-num-orgao">
						<label htmlFor="e-empresa-modal-num-orgao">Nº de inscrição no orgão de classe</label>
						<input
							value={numero_inscricao}
							onChange={e => setNumero_inscricao(e.target.value)}
							type="text"
							id="e-empresa-modal-num-orgao"
							placeholder="Digite aqui" />
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleElaborador} >Salvar</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}

function PlanoAcao(props) {

	const [o_que, setO_que] = useState('')
	const [quem, setQuem] = useState('')
	const [quando, setQuando] = useState(new Date())
	const [inicio, setInicio] = useState(new Date())
	const [como, setComo] = useState('')
	const [onde, setOnde] = useState('')
	const [porque, setPorque] = useState('')
	const [quanto, setQuanto] = useState('')
	const [empresaId, setEmpresaId] = useState('')
	const [tipologia, setTipologia] = useState('')
	const [currentValues, setCurrentValues] = useState('') //pesquisa
	const [options, setOptions] = useState([]) //opcoes de selecao
	const [searchQuery, setSearchQuery] = useState('')
	const [iniciado, setIniciado] = useState(false)
	const [concluido, setConcluido] = useState(false)

	const [habilita, setHabilita] = useState(false)

	const { aviso } = React.useContext(Context);

	const [carregando, setCarregando] = useState(false);

	var textOque = "Qual é a ação necessária/Qual a atividade a ser realizada"
	var textQuem = "Quem é a pessoa responsável por conduzir esse processo"
	var textQuando = "Quando termina o plano"
	var textInicio = "Quando inicia o plano"
	var textComo = "Detalhar como será feito para resolver a ação"
	var textOnde = "Detalhar onde essa ação deve ser realizada ( local, máquina,etc...)"
	var textPorque = "Geralmente é para resolver um problema, para reduzir ou "
		+ "eliminar um risco, aumentar a produtividade, para garantir a manutenção"
	var textQuanto = "Quanto vai custar, é para ter um rastreamento de quanto "
		+ "custou e qual é o payback (tempo de retorno do investimento inicial "
		+ "que foi aplicado no negócio ou projeto)"

	var textPesquisa = "Procure um plano de ação semelhante com base no campo 'O quê?' do 5W2H"
		+ " para agilizar o preenchimento (opcional)"

    const handleChange = (e, { value }) => {

		setCurrentValues(value);
		if (value != "") {
			setHabilita(true)
		} else {
			setHabilita(false)
		}

	}

	const handlePesquisa = () => {

		if ((searchQuery == undefined || searchQuery == "") && (currentValues == undefined || currentValues == "")) {

		} else {
			const r = window.confirm("Deseja puxar as informações do plano de ação " + searchQuery + " ?\n ATENÇÃO: Todos as informações já inseridas nesse plano de ação serão apagadas")

			if (r) {
				api.get("plano_acao_geral?plano=consulta").then(response => {

					response.data.resultado.forEach(item => {

						if (currentValues == item.id) {

							var dat_fim = new Date(item.quando)
							var dat_ini = new Date(item.inicio)
							setO_que(item.o_que)
							setQuem(item.quem)
							setQuando(dat_fim)
							setInicio(dat_ini)
							setComo(item.como)
							setOnde(item.onde)
							setPorque(item.porque)
							setQuanto(item.quanto)
							setIniciado(Boolean(false))
							setConcluido(Boolean(false))
						}

					})
				})
			}
		}

	}

	const DropPesquisa = React.useMemo(() =>
		(
			<Dropdown
				options={options}
				placeholder="Procure por ações( O quê? ) já cadastradas em outros riscos"
				search
				lazyLoad={true}
				selection
				fluid
				clearable
				onChange={handleChange}
				value={currentValues}
			/>
		), [currentValues, options, searchQuery])

	useEffect(() => {

		if (props.show) {
			setCarregando(true)
			async function initial() {

				try {

					setEmpresaId(sessionStorage.getItem('empresaId'))
				
					api.get("plano_acao_geral?plano=consulta").then(response => {
						var aux = []

						response.data.resultado.forEach(item => {
							aux.push({ key: item.id, text: item.o_que, value: item.id })
						})

						if (sessionStorage.getItem('planoId') != 0) {
							setOptions(aux.filter(item => item.value != sessionStorage.getItem('planoId')))
						} else {
							setOptions(aux)
						}

					})

					if (sessionStorage.getItem('planoId') != 0) {

						await api.get(`plano_acao_geral?empresa_id=${sessionStorage.getItem('empresaId')}`).then(response => {

							response.data.resultado.forEach(item => {

								if (sessionStorage.getItem('planoId') == item.id) {

									var dat_ini
									var dat_fim

									if(item.inicio == undefined){
										dat_ini = new Date()
									}else{
										dat_ini = new Date(item.inicio)
									}

									if(item.quando == undefined){
										var dat_fim = new Date()
									}else{
										var dat_fim = new Date(item.quando)
									}

									setO_que(item.o_que)
									setQuem(item.quem)
									setQuando(dat_fim)
									setInicio(dat_ini)
									setComo(item.como)
									setOnde(item.onde)
									setPorque(item.porque)
									setQuanto(item.quanto)
									setIniciado(Boolean(item.iniciado))
									setConcluido(Boolean(item.concluido))

								}

							})

						})
					}else{

						setO_que('')
						setQuem('')
						setQuando(new Date())
						setInicio(new Date)
						setComo('')
						setOnde('')
						setPorque('')
						setQuanto('')
						setIniciado(false)
						setConcluido(false)

					}

				} catch (error) {

					aviso('error', error)

				} finally {

					setCarregando(false)
				}

			} initial()
		}


	}, [props.show, sessionStorage.getItem('planoId')])

	const handleRegister = async (e) => {

		e.preventDefault()

		const data = {
			o_que: o_que,
			quem: quem,
			quando: quando,
			inicio: inicio,
			como: como,
			onde: onde,
			porque: porque,
			quanto: quanto,
			iniciado: iniciado,
			concluido: concluido,
			fk_empresa_id: empresaId
		}

		setCarregando(true)

		try {


			if (o_que == "" || quem == "" || inicio == null || quando == null || como == "" || onde == "" || porque == "" || quanto == ""|| quando == "") {
				aviso('warning', 'Preencha todos os campos!')
			} else {

				if (inicio > quando) {
					aviso('warning', 'Data de início superior à data de término!')
				} else {

					if (sessionStorage.getItem('planoId') == 0) {
						await api.post('plano_acao_geral?empresa_id=' + sessionStorage.getItem('empresaId'), data)
					} else {
						await api.patch("plano_acao_geral/" + sessionStorage.getItem('planoId'), data)
					}

					setCarregando(false)
					aviso('success', "Plano de ação salvo com sucesso")
					setQuando(new Date())
					setQuem('')
					setComo('')
					setOnde('')
					setPorque('')
					setQuanto('')
					setO_que('')
					setInicio(new Date())
					props.onHide()
				}

			}

		} catch (error) {

			aviso('error', error)

		} finally {
			setCarregando(false)

		}
	}

	const handleConcluido = () => {
		/*if(sessionStorage.getItem('dashboardPlano') === 'true' && prevConcluido == false && !concluido == true){
			aviso('info','É conveniente alterar a severidade e probabilidade do risco')
			sessionStorage.setItem('moveTop', true)
		}*/
		setConcluido(!concluido)
		if(iniciado === false){
			setIniciado(true)
		}
	}

	const handleIniciado = () => {
		setIniciado(!iniciado)
		if(concluido === true){
			setConcluido(false)
		}
	}

	return (

		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>

			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Plano de ação (5W2H)
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>

				<ReactTooltip backgroundColor="#006ab4" className="tip" id="aux" effect="solid" event='click' globalEventOff='click' multiline={true}></ReactTooltip>
				<Loading loading={carregando} message='Carregando...' />

				
				<div className="plano-pesquisa1">
					<label>Procurar planos de ações semelhantes <FiInfo style={{ cursor: "pointer" }} data-tip={textPesquisa} data-for="aux"></FiInfo> </label>
					<div className="plano-pesquisa2">
						{DropPesquisa}
						<button disabled={!habilita} onClick={handlePesquisa}><FiSearch /></button>
					</div>
				</div>
				
				<div className="plano-retangulo1">
					<div className="plano-aux1">
						<label htmlFor="">O quê?   <FiInfo style={{ cursor: "pointer" }} data-tip={textOque} data-for="aux"></FiInfo></label>
						<textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setO_que(e.target.value)} value={o_que} />
					</div>

					<div className="plano-aux1">
						<label htmlFor="">Quem?   <FiInfo style={{ cursor: "pointer" }} data-tip={textQuem} data-for="aux"></FiInfo></label>
						<textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setQuem(e.target.value)} value={quem} />
					</div>

					<div className="plano-aux1">
						<label htmlFor="">Onde?   <FiInfo style={{ cursor: "pointer" }} data-tip={textOnde} data-for="aux"></FiInfo></label>
						<textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setOnde(e.target.value)} value={onde} />
					</div>
				</div>

				<div className="plano-retangulo2">
					<div className="plano-aux1">
						<label htmlFor="">Por quê?    <FiInfo style={{ cursor: "pointer" }} data-tip={textPorque} data-for="aux"></FiInfo></label>
						<textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setPorque(e.target.value)} value={porque} />
					</div>

					<div className="plano-aux1">
						<label htmlFor="">Quanto?   <FiInfo style={{ cursor: "pointer" }} data-tip={textQuanto} data-for="aux"></FiInfo></label>
						<textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setQuanto(e.target.value)} value={quanto} />
					</div>

					<div className="plano-aux1">
						<label htmlFor="">Como?   <FiInfo style={{ cursor: "pointer" }} data-tip={textComo} data-for="aux"></FiInfo></label>
						<textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setComo(e.target.value)} value={como} />
					</div>
				</div>

				<div className="plano-retangulo3">

					<div className="plano-input">
						<label htmlFor="plano-input">Data Início   <FiInfo style={{ cursor: "pointer" }} data-tip={textInicio} data-for="aux"></FiInfo></label>
						<DatePicker locale="pt-BR" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" isClearable className="data-picker" dateFormat="dd/MM/yyyy" selected={inicio} onChange={date => setInicio(date)} />
					</div>
					<div className="plano-input">
						<label htmlFor="plano-input">Data Fim   <FiInfo style={{ cursor: "pointer" }} data-tip={textQuando} data-for="aux"></FiInfo></label>
						<DatePicker locale="pt-BR" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" isClearable className="data-picker" dateFormat="dd/MM/yyyy" selected={quando} onChange={date => setQuando(date)} />
					</div>
					<div className="plano-check">				
						<label style={{marginRight:'5px'}}>Iniciado?</label>
						<Checkbox checked={iniciado} onChange={handleIniciado} />
						<label style={{marginLeft:'10px',marginRight:'5px'}}>Concluído?</label>
						<Checkbox checked={concluido} onChange={handleConcluido} />
					</div>
				</div>

			</Modal.Body>

			<Modal.Footer>
				<Button onClick={handleRegister}>{sessionStorage.getItem('planoId') == 0 ? "Cadastrar" : "Salvar"}</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>

		</Modal>

	)
}