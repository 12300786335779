import React from 'react';
import { Context } from '../../Context/AuthContext';
import api from '../../services/api';
import Loading from '../Loading';
import { useState, useEffect } from 'react';
import { FiEdit } from 'react-icons/fi';
import { MdBlock } from 'react-icons/md'
import ReactTooltip from 'react-tooltip';
import { Modal, Button } from 'react-bootstrap'

import { Checkbox, Input } from 'semantic-ui-react'

import InfiniteScroll from 'react-infinite-scroll-component';

import './styles.css';

import { Menu } from '../../global.js';

export default function GestaoUsuarios() {

	const { aviso } = React.useContext(Context);

	const [carregando, setCarregando] = useState(false);
	const [filtro, setFiltro] = useState('')
	const [filtro2, setFiltro2] = useState('')
	const [name, setName] = useState('')
	const [users, setUsers] = useState('')
	const [user, setUser] = useState([])
	const [optionsSearch, setOptionsSearch] = useState([])
	const [optionsSearch2, setOptionsSearch2] = useState([])
	const [modalShow, setModalShow] = useState(false)

	const [foundUsers, setFoundUsers] = useState([])

	const [count, setCount] = useState({
		prev: 0,
		next: 10
	})

	const [hasMore, setHasMore] = useState(true);
	const [current, setCurrent] = useState(foundUsers.slice(count.prev, count.next))

	const getMoreData = () => {

		if (current.length === foundUsers.length) {
			setHasMore(false);
			return;
		}

		if (current.length > 0) {
			setTimeout(() => {
				setCurrent(current.concat(foundUsers.slice(count.prev + 10, count.next + 10)))
			}, 500)
			setCount((prevState) => ({ prev: prevState.prev + 10, next: prevState.next + 10 }))
		}

	}


	useEffect(() => {

		function inicial() {

			var keyword = name
			var usuarios_filtrados
			var usuariosF

			if (filtro !== '') {

				setFoundUsers([])
				setCurrent([].slice(0, 10))

				if (filtro == "Com acesso") {
					usuarios_filtrados = users.filter(item => (Boolean(item.bloqueado) !== true && new Date(item.termino) >= new Date()))
				} else if (filtro == "Sem acesso") {
					usuarios_filtrados = users.filter(item => (Boolean(item.bloqueado) === true || new Date(item.termino) < new Date()))
				} else {
					usuarios_filtrados = users
				}

				if (filtro2 === "Administrador") {
					usuariosF = usuarios_filtrados.filter(item => (item.permissao == 2))
				} else if (filtro2 === "Acesso total") {
					usuariosF = usuarios_filtrados.filter(item => (item.permissao == 0))
				} else if (filtro2 === "Acesso limitado") {
					usuariosF = usuarios_filtrados.filter(item => (item.permissao == 1))
				} else if (filtro2 === "Treinamento") {
					usuariosF = usuarios_filtrados.filter(item => (item.permissao == -1))
				} else if (filtro2 === "Auditoria") {
					usuariosF = usuarios_filtrados.filter(item => (item.permissao == -2))
				} else {		
					usuariosF = usuarios_filtrados
				}


				if (keyword !== '') {

					const results = usuariosF.filter((user) => {
						return (user.name.toLowerCase().includes(keyword.toLowerCase()) || user.email.toLowerCase().includes(keyword.toLowerCase()) || user.license_key.toLowerCase().includes(keyword.toLowerCase()));
					});

					setHasMore(true)
					setFoundUsers(results);
					setCount({
						prev: 0,
						next: 10
					})
					setTimeout(() => {
						setCurrent(results.slice(0, 10))
					}, 0)


				} else {

					setHasMore(true)
					setFoundUsers(usuariosF);
					setCount({
						prev: 0,
						next: 10
					})
					setTimeout(() => {
						setCurrent(usuariosF.slice(0, 10))
					}, 0)


				}

			}

		}

		inicial()


	}, [name, filtro, filtro2, users])

	useEffect(() => {

		handleRefresh()

	}, [])

	async function handleRefresh() {

		setCarregando(true)

		try {

			var response = await api.get('login')

			setFoundUsers([])
			setCurrent([].slice(0, 10))

			setUsers(response.data.resultado)

			setFiltro2("Todos")
			setOptionsSearch2(["Todos", "Administrador", "Acesso total", "Acesso limitado", "Treinamento", "Auditoria"])

			setFiltro("Todos")
			setOptionsSearch(['Todos', 'Com acesso', 'Sem acesso'])

		} catch (error) {
			console.log(error)
		} finally {
			setCarregando(false)
		}

	}

	const handleEdit = (e, user) => {
		setUser(user)
		setModalShow(true)
	}

	const handleBlock = async (e, user) => {
		try {	
			if (Boolean(user.bloqueado) == true) {
				await api.put('login/' + user.id, { "block": false })
			} else {
				await api.put('login/' + user.id, { "block": true })
			}
			aviso("success","Usuário atualizado com sucesso")
			handleRefresh()
		} catch (error) {
			aviso('error', error.response.data.mensagem, true)
		} 

	}

	return (
		<div>
			<Menu />
			<Loading loading={carregando} message='Carregando...' />
			<EditarUser show={modalShow} backdrop="static" user={user} onHide={() => { setModalShow(false); handleRefresh() }} />
			<div className="basic-content">

				<div className="titulo">
					<h1>Gestão de licenças</h1>
				</div>

				<div className="gestao-main">

					<div className="gestao-box3">

						<div className="gestao-p1">
							<Input fluid size='small' value={name} icon='search' placeholder='Pesquise...' onChange={e => { setName(e.target.value) }} />
						</div>

						<div className="gestao-P">

							<div className="gestao-p3">
								<select className="gestao-select" value={filtro} onChange={e => { setFiltro(e.target.value) }}>
									{optionsSearch.map((nome) => (
										<option key={nome} value={nome}>{nome}</option>
									))}
								</select>
							</div>

							<div className="gestao-p2">
								<select className="gestao-select" value={filtro2} onChange={e => { setFiltro2(e.target.value) }}>
									{optionsSearch2.map((nome) => (
										<option key={nome} value={nome}>{nome}</option>
									))}
								</select>
							</div>

						</div>

					</div>
					<div id="scrollableDiv" className="gestao-ListBox">

						<InfiniteScroll
							dataLength={current.length}
							next={getMoreData}
							hasMore={hasMore}
							scrollableTarget="scrollableDiv"
						>

							{current.map((user) => (
								<div key={user.id} className="gestao-ListItem">
									<ReactTooltip id={toString(user.id)} place="top" effect="solid"></ReactTooltip>
									<div className="gestao-item">
										<div className="gestao-item-block">
											<div className="gestao-item-block-p1">
												<div className="gestao-item-p1">
													<a><div
														data-tip={
															new Date(user.termino) < new Date() ?
																"Licença expirada" : Boolean(user.bloqueado) == true ?
																	"Usuário bloqueado" : "Acesso liberado"
														}
														data-for={toString(user.id)}
														className={
															new Date(user.termino) < new Date() ?
																"Expirado" : Boolean(user.bloqueado) == true ?
																	"Block" : "Liberado"
														}
													/>&nbsp;<b>Nome: </b>{user.name}</a>
														<a className='esconde'><b>E-mail: </b>{user.email}</a>
													<a><b>Permissão: </b>{
														user.permissao == 2? "Administrador":
														user.permissao == 1? "Acesso limitado":
														user.permissao == -1? "Treinamento":
														user.permissao == -2? "Auditoria":"Acesso total"
													}</a>
													<a>
														<b>Status: </b>{
															new Date(user.termino) < new Date() ?
																"Licença expirada" : Boolean(user.bloqueado) == true ?
																	"Usuário bloqueado" : "Acesso liberado"
														}
													</a>

												</div>
												<div className="gestao-item-p2">
													<a><b>Tipo de licença: </b>{user.licenca}</a>
													<a><b>Data de início: </b>{new Date(user.inicio).toLocaleDateString('pt-BR')}</a>
													<a><b>Acesso até: </b>{new Date(user.termino).toLocaleDateString('pt-BR')}</a>
													<a><b>Dias restantes: </b>{user.diasRestantes}</a>
													<a><b>Último acesso: </b>{new Date(user.ultimo_acesso).toLocaleDateString('pt-BR')} - {new Date(user.ultimo_acesso).toLocaleTimeString('pt-BR')}</a>
												</div>

												<div className="gestao-icons">

													<i className="gestao-edit">
														<FiEdit data-tip="Editar" data-for={toString(user.id)} onClick={e => handleEdit(e, user)} />
													</i>
													<i className={
														Boolean(user.bloqueado) == true? "gestao-block" : "gestao-unblock"
													}>
														<MdBlock data-tip={
															Boolean(user.bloqueado) == true? "Desbloquear" : "Bloquear"
														} data-for={toString(user.id)} onClick={e => handleBlock(e, user)} />
													</i>

												</div>
											</div>
											<div className="gestao-item-block-p2">
												<a><b>Licença: </b>{user.license_key}</a>
											</div>
										</div>

									</div>

								</div>

							))}
						</InfiniteScroll>
					</div>
					<div className='gestao-footer'>
						Usuários encontrados: {foundUsers.length}
					</div>
				</div>



			</div>

		</div>
	);
}

function EditarUser(props) {


	const [carregando, setCarregando] = useState(false);
	const [tipo, setTipo] = useState('')
	const [prevTi, setPrevTi] = useState('')
	const [opt, setOpt] = useState([])
	const [renew, setRenew] = useState(false)
	const [permissao, setPermissao] = useState('')
	const [prevPer, setPrevPer] = useState('')
	const { aviso } = React.useContext(Context);


	useEffect(() => {

		async function inicial() {

			if (props.show) {

				setPermissao(props.user.permissao)
				setPrevPer(props.user.permissao)
				setTipo(props.user.licenca)
				setPrevTi(props.user.licenca)
				setOpt(["D-1", "D-3", "D-7", "D-14", "M-1", "M-3", "M-6", "M-12"])
				setRenew(false)

			}

		} inicial()

	}, [props.show])

	const handleUpdate = async (e) => {

		e.preventDefault()

		var data = {}

		if (prevPer == permissao && renew == false) {
			aviso('info', "Nenhuma alteração feita")
			props.onHide()
		} else {

			if (renew == true) {
				data.licenca = tipo
			} else {
				data.permissao = permissao
			}

			setCarregando(true)

			try {

				var mensagem = "Usuário atualizado com sucesso"
				
				await api.put('login/' + props.user.id, data)
				
				aviso('success', mensagem)

			} catch (error) {

				aviso('error', error.response.data.mensagem, true)

			} finally {

				setCarregando(false)
				props.onHide()
			}

		}

	}

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Editar usuário {props.user.name} ({props.user.email})
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />

				<div className="gestao-P">

					<div className="gestao-p3">
						<label>Permissão</label>
						<select className="gestao-select" value={permissao} onChange={e => { setPermissao(e.target.value) }}>
							<option value={-2}>Auditoria</option>
							<option value={-1}>Treinamento</option>
							<option value={0}>Acesso total</option>
							<option value={1}>Acesso limitado</option>
							<option value={2}>Administrador</option>
						</select>
					</div>

					<div className="gestao-p1-2">
						<label>Renovar licença?</label>
						<Checkbox onClick={() => setRenew(!renew)} toggle/>
					</div>

					{renew?

					<div className="gestao-p2">
						<label>Tipo de licença</label>
						<select className="gestao-select" value={tipo} onChange={e => { setTipo(e.target.value) }}>
							{opt.map((nome) => (
								<option key={nome} value={nome}>{nome}</option>
							))}
						</select>
					</div>
					:
					<div className="gestao-p2"/>
					}

				</div>

			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleUpdate}>Salvar</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}